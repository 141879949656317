import $ from 'jquery';
import {Pagination, Autoplay, Mousewheel, EffectFade} from 'swiper/modules';
import {Swiper} from 'swiper'
import 'swiper/css';

Swiper.use([Pagination, Autoplay, Mousewheel, EffectFade])

let numbersSlider = $('.js-numbers-slider');

if (numbersSlider.length > 0) {
  numbersSlider.each(function () {
    let numbersSliderSwiper = new Swiper(this, {
      slidesPerView: "auto",
      loop: false,
      centeredSlides: true,
      centeredSlidesBounds: true,
      autoplay: {
        delay: 3000,
      },

      on: {
        resize: function enableOnlyMobile(numbersSliderSwiper) {
          if (767 < window.innerWidth) {
            numbersSliderSwiper.disable()
          } else {
            numbersSliderSwiper.enable()
          }
        },
      },
    });

    numbersSliderSwiper.init();
  });
}

let countItems = document.querySelectorAll('.js-count');

countItems.forEach(function (e) {
  let itemContent = e.innerHTML
  let pureNumber = itemContent.replace(/\D/g, '')
  e.innerHTML = itemContent.replace(pureNumber, "<span>" + pureNumber + "</span>")

  let hasBeenCalled = false;

  addEventListener("scroll", () => {
    if (isScrolledIntoView(e) === true) {
      if (!hasBeenCalled) {
        counter(e, pureNumber)
        hasBeenCalled = true;
      }
    }
  });
})

function counter(elem, number) {
  let start = 0,
    current = start,
    range = number - start,
    step = Math.abs(Math.floor(2500 / range)),
    timer = setInterval(() => {
      current++;
      elem.querySelector('span').innerHTML = current;

      if (current == number) {
        clearInterval(timer);
      }
    }, step);
}

function isScrolledIntoView(elem) {
  let docViewTop = $(window).scrollTop();
  let docViewBottom = docViewTop + $(window).height();
  let elemTop = $(elem).offset().top;
  let elemBottom = elemTop + $(elem).height();

  return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

